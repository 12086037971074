import settings from '@/settings'

/**
 * 資料查詢
 */

class History {
  // 電表資料查詢
  meterHistory(meterId, params) {
    return axios.get(
      settings.api.fullPath + `/ems/report/history/meter/${meterId}`,
      {
        params: params
      }
    )
  }

  // 教室冷氣資料查詢
  classAcHistory(classroomId, params) {
    return axios.get(
      settings.api.fullPath +
        `/ems/report/history/classroom/${classroomId}/air-conditioner`,
      {
        params: params
      }
    )
  }

  // 教室冷氣合併平均資料查詢
  classAcMergedHistory(classroomId, params) {
    return axios.get(
      settings.api.fullPath +
        `/ems/report/history/classroom/${classroomId}/air-conditioner/merged`,
      {
        params: params
      }
    )
  }

  // 氣象資料查詢
  climatologyHistory(params, schoolId) {
    return axios.get(
      settings.api.fullPath +
        `/ems/report/history/climatology/school/${schoolId}`,
      {
        params: params
      }
    )
  }

  // 群組電表合計資料查詢
  groupMeterHistory(groupId, params) {
    return axios.get(
      settings.api.fullPath + `/ems/report/history/meter-by-group/${groupId}`,
      {
        params: params
      }
    )
  }

  // 電表資料查詢下載
  meterHistoryExport(params, fileType, meterId) {
    return axios.get(
      settings.api.fullPath +
        `/ems/report/history/meter/${meterId}/file.${fileType}`,
      {
        params: params
      }
    )
  }

  // 教室冷氣資料查詢下載
  classAcHistoryExport(params, fileType, classroomId) {
    return axios.get(
      settings.api.fullPath +
        `/ems/report/history/classroom/${classroomId}/air-conditioner/file.${fileType}`,
      {
        params: params
      }
    )
  }

  // 教室冷氣合併平均資料查詢下載
  classAcMergedHistoryExport(params, fileType, classroomId) {
    return axios.get(
      settings.api.fullPath +
        `/ems/report/history/classroom/${classroomId}/air-conditioner/merged/file.${fileType}`,
      {
        params: params
      }
    )
  }

  // 氣象資料查詢下載
  climatologyHistoryExport(params, fileType, schoolId) {
    return axios.get(
      settings.api.fullPath +
        `/ems/report/history/climatology/school/${schoolId}/file.${fileType}`,
      {
        params: params
      }
    )
  }

  // 群組電表合計資料查詢下載
  groupMeterHistoryExport(params, fileType, groupId) {
    return axios.get(
      settings.api.fullPath +
        `/ems/report/history/meter-by-group/${groupId}/file.${fileType}`,
      {
        params: params
      }
    )
  }
}

var history = new History()
export default history
