<template>
  <div class="pa-3">
    <AnalysisItem
      v-for="item in analysisList"
      :key="item.key"
      :title="item.title"
      :kind="item.key"
      :displayDataTypes="displayDataTypes[item.key]"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AnalysisItem from '@/components/Analysis/AnalysisItem'

export default {
  components: {
    AnalysisItem
  },
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      analysisList: [
        { key: 'meter', title: '電表分析' },
        { key: 'solar', title: '發電電表分析' },
        { key: 'ac', title: '冷氣分析' },
        { key: 'env', title: '環境分析' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      meterTabTypes: 'analysis/meter',
      solarTabTypes: 'analysis/solar',
      acTabTypes: 'analysis/ac',
      envTabTypes: 'analysis/env',
      schoolMeters: 'analysis/schoolMeters',
      buildings: 'building/buildings',
      groups: 'groups/groups'
    }),

    displayDataTypes() {
      return {
        meter: this.meterTabTypes,
        solar: this.solarTabTypes,
        ac: this.acTabTypes,
        env: this.envTabTypes
      }
    }
  },
  mounted() {
    if (!this.schoolMeters || this.schoolMeters[0]?.schoolId !== this.tagId) {
      this.getSchoolMeters(this.tagId)
    }
    if (
      !this.buildings ||
      (this.buildings[0] && this.buildings[0].schoolId !== this.tagId)
    ) {
      this.getAllBuildings(this.tagId)
    }
    this.getGroups(this.tagId)
  },
  methods: {
    ...mapActions({
      getSchoolMeters: 'analysis/getSchoolMeters',
      getAllBuildings: 'building/getAllBuildings',
      getGroups: 'groups/getGroups'
    })
  }
}
</script>
